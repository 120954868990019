
import Vue from 'vue';
import Component from 'vue-class-component';

import webConfig from '@/config';
import { UserModule } from '@/store/modules';
import UserService from '@/services/user.service';

import OnboardLayout from '@/components/OnboardLayout.vue';
import Title from '@/components/common/Title.vue';
import { OnboardingSteps } from '@/components/onboarding';

@Component({
  components: { OnboardLayout, OnboardingSteps, Title }
})
export default class CreditCardSetup extends Vue {
  olivePublicKey = webConfig.api.olivePublicKey;
  oliveCardSdk = webConfig.api.oliveCardSdk;
  currentEnv = webConfig.app.envName;

  async mounted(): Promise<void> {
    this.loadCardScript();
    window.creditCardSetup = this;
  }

  loadCardScript(): void {
    let scriptEl = document.getElementById('olive-link-card-form');
    scriptEl?.setAttribute('data-public-key', this.olivePublicKey);

    const scriptSdk = document.createElement('script');
    scriptSdk.src = this.oliveCardSdk;
    scriptSdk.id = 'olive-link-card-form';
    scriptSdk.type = 'application/javascript';

    const attributes = {
      'data-public-key': this.olivePublicKey,
      'data-container-div': 'olive-sdk-container',
      'data-auto-open': 'true',
      'data-include-cvv': 'true',
      'data-include-address': 'false',
      'data-include-header': 'false'
    };

    (Object.keys(attributes) as Array<keyof typeof attributes>).forEach((key) => {
      if (attributes[key] != null) {
        return scriptSdk.setAttribute(key, attributes[key]);
      }
    });

    // Set data-environment
    if (this.currentEnv != 'production') scriptSdk.setAttribute('data-environment', 'sandbox');

    let scriptCallback = document.createElement('script');
    scriptCallback.innerHTML = `function callback(paymentMethodData, error, successFlag) {
     
     window.creditCardSetup.handleEnrollCardResponse(paymentMethodData, error, successFlag);
      
    }`;

    let divScript = document.getElementById('script-sdk');
    divScript?.appendChild(scriptCallback);
    divScript?.appendChild(scriptSdk);
  }

  async handleEnrollCardResponse(paymentMethodData: any, error: any, successFlag: any) {
    if (successFlag) {
      try {
        // Create card in the back-end (including Olive)
        // We flag that we're adding this payment method during onboarding
        // because the handling in the back-end is different than when
        // adding a payment method for a verified/onboarded user
        await UserService.addPaymentMethod(paymentMethodData);

        // Fetch updated user information
        let user = await UserService.fetchUser();
        UserModule.setUser(user);
        this.$router.replace('/set-notifications');
      } catch (err: any) {
        const errorMsg = this.$getError(err);
        this.$notifyError(errorMsg);
      }
    } else {
      this.$notifyError(error);
    }
  }
}
